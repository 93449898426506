import React from "react"
import "./style.scss"
import Event1IMG from "../../../../../assets/img/event1.jpg"
import Event2IMG from "../../../../../assets/img/event2.jpeg"
import Event3IMG from "../../../../../assets/img/event3.jpg"

const OutsourcingEventsPage = () => {
  return (
    <div className="events-page">
      <div className="events-page-wrapper">
        <h1 className="events-title">Eventos</h1>
        <p className="events-subtitle">Lorem ipsum dolor sit amet.</p>
        <div className="events-content">
          <div className="events-images">
            <img src={Event1IMG} alt="event1" className="event1-img" />
            <img src={Event2IMG} alt="event2" className="event2-img" />
            <img src={Event3IMG} alt="event3" className="event3-img" />
          </div>
          <div className="events-description">
            <p>
              Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem
              ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum
              dolor sit amet. Lorem ipsum dolor sit amet.{" "}
            </p>
            <p>
              Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem
              ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum
              dolor sit amet. Lorem ipsum dolor sit amet.{" "}
            </p>
            <p>
              Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem
              ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum
              dolor sit amet. Lorem ipsum dolor sit amet.{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OutsourcingEventsPage
